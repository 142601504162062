<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="breadcrumb__wrapper">
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                           <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Login</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="login-area pt-20 pb-60 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="container-formulario">
                     <!-- Acessar -->
                     <div class="row" v-if="config.semConta != true && config.esqueceuSenha == false">
                        <div class="col-12 px-1">
                           <div class="section__head mb-30">
                              <div class="w-100 section__title mb-1">
                                 <h3 class="text-center">Login</h3>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 mb-20">
                           <p class="text-center" v-html="sourceLogin.acesso"></p>
                        </div>
                        <div class="col-12 mb-20">
                           <label class="limitador"><i class="far fa-id-card-alt font-12 me-1"></i> Usuário <small class="font-10 text-secondary ms-1">CNPJ ou Email cadastrado como perfil</small></label>
                           <input type="text" v-model="dados.username" maxlength="200" />
                        </div>
                        <div class="col-12 mb-20">
                           <label class="limitador"><i class="far fa-lock font-12 me-1"></i> Senha</label>
                           <input type="password" v-model="dados.senha" maxlength="200" />
                        </div>
                        <div class="col-12 mb-20">
                           <div class="header__action d-flex">
                              <ul>
                                 <li class="lh-1"><a href="javascript:;" @click="config.semConta = true;">Primeiro acesso?</a></li>
                                 <li class="lh-1"><a href="javascript:;" @click="config.esqueceuSenha = true;">Esqueci minha senha</a></li>
                              </ul>
                           </div>
                        </div>
                        <div class="col-12 text-end">
                           <button class="t-y-btn t-y-btn-2" @click="login">Acessar <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                        </div>
                     </div>

                     <div class="row" v-else-if="config.semConta == true">
                        <div class="col-12 px-1">
                           <div class="section__head mb-30">
                              <div class="w-100 section__title mb-1">
                                 <h3 class="text-center">Primeiro Acesso</h3>
                              </div>
                           </div>
                        </div>
                        <div class="col-12">
                           <p class="info-danger" v-html="sourceLogin.primeiroAcesso"></p>
                        </div>
                        <div class="col-12 text-center">
                           <button class="t-y-btn t-y-btn-2" @click="config.semConta = false;"><i class="fas fa-chevron-left font-12 me-2"></i> Voltar</button>
                        </div>
                     </div>

                     <div class="row" v-else-if="config.esqueceuSenha != false">
                        <div class="col-12 px-1">
                           <div class="section__head mb-30">
                              <div class="w-100 section__title mb-1">
                                 <h3 class="text-center">Esqueci minha senha</h3>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 mb-20">
                           <p class="text-center" v-html="sourceLogin.esqueceuSenha"></p>
                        </div>
                        <div class="col-12 mb-20">
                           <label class="limitador"><i class="far fa-envelope font-12 me-1"></i> Email cadastrado</label>
                           <input type="text" v-model="dados.email" />
                        </div>
                        <div class="col-6">
                           <button class="t-y-btn t-y-btn-2" @click="config.esqueceuSenha = false;"><i class="fas fa-chevron-left font-12 me-2"></i> Voltar</button>
                        </div>
                        <div class="col-6 text-end" v-if="config.esqueceuSenha != null">
                           <button class="t-y-btn t-y-btn-2" @click="recuperarConta">Enviar <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                        </div>
                        <div class="col-12" v-else>
                           <p class="info-danger mb-0">Verifique sua caixa de email</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Login',
   data: function() {
		return {
			dados: {'username': '', 'senha': '', 'email': ''},
         config: {'semConta': false, 'esqueceuSenha': false}
		}
	},
   computed: {
		... mapState({
         sourceLogin: state => state.source.login,
         urlRest: state => state.urlRest
		})
	},
   methods: {
      login : function () {
         if (this.dados.username.length > 0 && this.dados.senha.length > 0) {
            this.$store.dispatch('login', {'usuario': this.dados.username, 'senha': this.dados.senha, 'redirect': false});
         }
      },
      recuperarConta : function () {
         if (this.dados.email.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email inválido!'
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'usuario/recuperarSenha',
				params: {
               email: this.dados.email.trim()
            }
         }).then(() => {
            this.config.esqueceuSenha = null;

         }).catch((error) => {
            if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               })
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
   }
}

</script>

<style scoped>

.info-danger {
   background-color: #ec2125;
   color: #fff;
   border-radius: 4px;
   width: max-content;
   max-width: 100%;
   padding: 10px 25px;
   margin: 50px auto 65px;
}

</style>